// src/components/Dropdown/DropdownGroup.js
import React from 'react';
import { FaIndustry, FaBrush, FaServer } from 'react-icons/fa';
import classes from './DropdownGroup.module.scss';

const icons = {
  "bx bx-factory": <FaIndustry />,
  "bx bx-brush": <FaBrush />,
  "bx bx-server": <FaServer />,
};

const DropdownGroup = ({ title, icon, children }) => {
  return (
    <div className={classes.dropdownGroup}>
      <div className={classes.dropdownTitle}>
        <span className={classes.dropdownIcon}>{icons[icon]}</span>
        <span className={classes.textBase}>{title}</span>
      </div>
      {children}
    </div>
  );
};

export default DropdownGroup;
