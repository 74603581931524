import React, { useState } from 'react';
import classes from './ContactUs.module.scss'; // Импорт SCSS модулей
import MainLayout from '../../components/MainLayout/MainLayout';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    phone: '',
    company: '',
    postalCode: '',
    industry: '',
    employeeCount: '',
    contactReason: '',
    additionalNotes: ''
  });

  const [statusMessage, setStatusMessage] = useState(''); // Для отображения статуса отправки

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        body: data, // Отправляем данные формы
      });

      if (response.ok) {
        setStatusMessage('Форма успешно отправлена!');
      } else {
        setStatusMessage('Ошибка при отправке формы');
      }
    } catch (error) {
      setStatusMessage('Ошибка при отправке формы');
    }
  };

  return (
    <MainLayout>
      <div className={classes.supportPage}>
        <div className={classes.leftColumn}>
          <h1>Нам интересно узнать больше о вас</h1>
          <p>Помощь таким компаниям, как ваша, — наша цель. Заполните форму, и один из наших консультантов по поддержке клиентов свяжется с вами и расскажет, как мы можем работать вместе.</p>
          <hr />
          <h2 className={classes.contactTitle}>Контакты</h2>
          <p className={classes.contactDescription}>
            Если у вас есть вопросы или вам нужна дополнительная информация, пожалуйста, свяжитесь с нами.
          </p>
          <div className={classes.contactDetails}>
            <div className={classes.contactItem}>
              <h3>Email</h3>
              <p><a href="mailto:yut.63@bk.ru">yut.63@bk.ru</a></p>
            </div>
            <div className={classes.contactItem}>
              <h3>Телефон</h3>
              <p><a href="tel:+79608498880">+7 (960) 849 8880</a></p>
            </div>
          </div>
        </div>

        <div className={classes.rightColumn}>
          <h2 className={classes.supportTitle}>Форма обратной связи</h2>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.formGroup}>
              <label htmlFor="firstName">Имя*</label>
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                value={formData.firstName} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="lastName">Фамилия*</label>
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                value={formData.lastName} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="email">Рабочий адрес электронной почты*</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="position">Должность*</label>
              <input 
                type="text" 
                id="position" 
                name="position" 
                value={formData.position} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="phone">Телефон*</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone" 
                value={formData.phone} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="company">Название организации*</label>
              <input 
                type="text" 
                id="company" 
                name="company" 
                value={formData.company} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="industry">Отрасль*</label>
              <input 
                type="text" 
                id="industry" 
                name="industry" 
                value={formData.industry} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="contactReason">Причина контакта*</label>
              <input 
                type="text" 
                id="contactReason" 
                name="contactReason" 
                value={formData.contactReason} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="additionalNotes">Дополнительные примечания (необязательно)</label>
              <textarea 
                id="additionalNotes" 
                name="additionalNotes" 
                value={formData.additionalNotes} 
                onChange={handleChange} 
              />
            </div>
            <button type="submit" className={classes.submitButton}>Отправить</button>
          </form>
          {statusMessage && <div className={classes.statusMessage}>{statusMessage}</div>}
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactUsPage;
