import React from 'react';
import classes from './Statistic.module.scss';
import tltazot from '../../assets/images/tolyattiazot.jpg';
import ladaarena from '../../assets/images/lada-arena.webp';
import avtovaz from '../../assets/images/avtovaz.png';


const Statistic = () => {
    return (
    <section className={classes.statisticSection}>
        <div className={classes.statisticContainer}>
            <h2 className={classes.title}>Нам доверяют сотни клиентов всех типов</h2>
            <ul className={classes.statList}>
                <li className={classes.statItem}>
                    <div className={classes.statValue}>100 миллионов+</div>
                    <div className={classes.statDescription}>Помогли заработать нашим клиентам</div>
                </li>
                <li className={classes.statItem}>
                    <div className={classes.statValue}>500+</div>
                    <div className={classes.statDescription}>Клиентов по всей России</div>
                </li>
                <li className={classes.statItem}>
                    <div className={classes.statValue}>92</div>
                    <div className={classes.statDescription}>Из 100 положительных отзывов</div>
                </li>
                <li className={classes.statItem}>
                    <div className={classes.statValue}>90</div>
                    <div className={classes.statDescription}>Из 100 видов химии можем произвести</div>
                </li>
            </ul>
        </div>
        
        <div className={classes.logos}>
            <h2 className={classes.smartTitle}>Умные лидеры выбирают умные покупки для бизнеса</h2>
            <p className={classes.smartDescription}>
                Узнайте, как более 500 организаций упрощают закупки для своего бизнеса с помощью комплексного
                обслуживания, аналитики в реальном времени и функций, экономящих время, — все это на основе самого большого в России
                выбора товаров для бизнеса.
            </p>
            {/* Изображения логотипов */}
            {/*<img src={tltazot} alt="" />
            <img src={ladaarena} alt="" />
            <img src={avtovaz} alt="" />*/}
            {/* Добавьте остальные логотипы аналогично */}
        </div>
    </section>
    );
  };

export default Statistic;