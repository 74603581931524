// src/components/Dropdown/DropdownContent.js
import React from 'react';
import { Link } from 'react-router-dom';
import DropdownGroup from './DropdownGroup';
import classes from './DropdownContent.module.scss';

const DropdownContent = () => {
  return (
    <div className={classes.dropdownContent}>
      <div className={classes.dropdownColumn}>
      <DropdownGroup title="Производство и поставка" icon="bx bx-factory">
  <ul className={classes.dropdownItems}>
    <li>
      <Link
        to="/order/textile-production"
        className={classes.dropdownLink}
      >
        Текстильное производство
      </Link>
    </li>
    <li>
      <Link
        to="/order/chemical-production"
        className={classes.dropdownLink}
      >
        Производство бытовой химии
      </Link>
    </li>
  </ul>
</DropdownGroup>
</div>
<div className={classes.dropdownColumn}>
  <DropdownGroup
    title="Услуги по дизайну и отделке"
    icon="bx bx-brush"
  >
    <ul className={classes.dropdownItems}>
      <li>
        <Link
          to="/order/room-decoration"
          className={classes.dropdownLink}
        >
          Отделка помещений
        </Link>
      </li>
      <li>
        <Link
          to="/order/custom-painting"
          className={classes.dropdownLink}
        >
          Производство картин под заказ
        </Link>
      </li>
      <li>
        <Link
          to="/order/interior-design"
          className={classes.dropdownLink}
        >
          Дизайн интерьера и ландшафта
        </Link>
      </li>
    </ul>
  </DropdownGroup>
</div>
<div className={classes.dropdownColumn}>
  <DropdownGroup title="Администрирование" icon="bx bx-server">
    <ul className={classes.dropdownItems}>
      <li>
        <Link
          to="/order/1c-bitrix-admin"
          className={classes.dropdownLink}
        >
          Администрирование 1С и Битрикс
        </Link>
      </li>
    </ul>
  </DropdownGroup>

      </div>
    </div>
  );
};

export default DropdownContent;
