// src/components/MainLayout/MainLayout.js
import React from 'react';
import classes from './MainLayout.module.scss';

const MainLayout = ({ children }) => {
  return (
    <div className={classes.mainLayout}>
      {children}
    </div>
  );
};

export default MainLayout;