import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '../../components/MainLayout/MainLayout';
import classes from './Order.module.scss';

const categories = {
  production: 'Производство и поставка',
  design: 'Услуги по дизайну и отделке',
  administration: 'Администрирование',
};

const servicesData = {
  'textile-production': {
    title: 'Текстильное производство',
    description:
      'Мы предлагаем полный цикл текстильного производства: от разработки дизайна до пошива готовых изделий.',
    categoryId: 'production',
  },
  'chemical-production': {
    title: 'Производство бытовой химии',
    description:
      'Наше предприятие производит экологически безопасные средства бытовой химии.',
    categoryId: 'production',
  },
  'room-decoration': {
    title: 'Отделка помещений',
    description:
      'Профессиональная отделка жилых и коммерческих помещений.',
    categoryId: 'design',
  },
  'custom-painting': {
    title: 'Производство картин под заказ',
    description:
      'Создаем уникальные картины по индивидуальному заказу.',
    categoryId: 'design',
  },
  'interior-design': {
    title: 'Дизайн интерьера и ландшафта',
    description:
      'Предлагаем услуги по разработке дизайна интерьера и ландшафтного дизайна.',
    categoryId: 'design',
  },
  '1c-bitrix-admin': {
    title: 'Администрирование 1С и Битрикс',
    description:
      'Обеспечиваем профессиональное администрирование систем 1С и Битрикс.',
    categoryId: 'administration',
  },
};

const Order = () => {
  const { serviceId } = useParams();
  const [selectedServiceId, setSelectedServiceId] = useState(serviceId);
  const service = servicesData[selectedServiceId] || null;
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    service ? service.categoryId : ''
  );

  // Состояние для полей формы
  const [formData, setFormData] = useState({
    companyName: '',
    fullName: '',
    phone: '',
    email: '',
    notes: '',
    file: null,
  });

  const [statusMessage, setStatusMessage] = useState(''); // Для отображения статуса отправки

  const handleCategoryChange = (event) => {
    const newCategoryId = event.target.value;
    setSelectedCategoryId(newCategoryId);

    const firstServiceId = Object.entries(servicesData).find(
      ([, data]) => data.categoryId === newCategoryId
    )?.[0];

    setSelectedServiceId(firstServiceId || '');
  };

  const handleServiceChange = (event) => {
    setSelectedServiceId(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'file') {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append('companyName', formData.companyName);
    data.append('fullName', formData.fullName);
    data.append('phone', formData.phone);
    data.append('email', formData.email);
    data.append('notes', formData.notes);
    if (formData.file) {
      data.append('file', formData.file);
    }

    try {
      const response = await fetch('/api/order', {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        setStatusMessage('Заявка успешно отправлена');
      } else {
        setStatusMessage('Ошибка при отправке');
      }
    } catch (error) {
      setStatusMessage('Ошибка при отправке');
    }
  };

  return (
    <MainLayout>
      <div className={classes.orderContainer}>
        {service ? (
          <>
            <div className={classes.serviceInfo}>
              <h1 className={classes.serviceTitle}>{service.title}</h1>
              <p className={classes.serviceDescription}>
                {service.description}
              </p>
            </div>
            <form className={classes.feedbackForm} onSubmit={handleSubmit}>
              <div className={classes.formGroup}>
                <label htmlFor="category-select">Выберите категорию:</label>
                <select
                  id="category-select"
                  value={selectedCategoryId}
                  onChange={handleCategoryChange}
                >
                  {Object.entries(categories).map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="service-select">Выберите услугу:</label>
                <select
                  id="service-select"
                  value={selectedServiceId}
                  onChange={handleServiceChange}
                >
                  {Object.entries(servicesData)
                    .filter(
                      ([, data]) => data.categoryId === selectedCategoryId
                    )
                    .map(([id, data]) => (
                      <option key={id} value={id}>
                        {data.title}
                      </option>
                    ))}
                </select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="companyName">
                  Название компании<span>*</span>
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="fullName">
                  Имя и фамилия<span>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.formGroup}>
                <label>
                  Номер телефона или почта<span>*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Номер телефона"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="notes">Дополнительные заметки</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="file">Прикрепить файл</label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleInputChange}
                />
              </div>
              <button type="submit" className={classes.submitButton}>
                Отправить запрос
              </button>
            </form>
            {statusMessage && (
              <div className={classes.statusMessage}>{statusMessage}</div>
            )}
          </>
        ) : (
          <div className={classes.notFound}>
            <h1>Услуга не найдена</h1>
            <p>Извините, запрашиваемая услуга не существует.</p>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Order;
