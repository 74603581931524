// src/components/Navbar/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Burger from '../Burger/Burger';
import Menu from '../Menu/Menu';
import AccountButton from '../AccountButton/AccountButton';
import classes from './Navbar.module.scss';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarInner}>
        <Link to="/" className={classes.brand}>YUT Profit<br />ООО УЮТ ПРОФИТ</Link>
      </div>
      <Menu isOpen={isOpen} />
      <div className={classes.navbarRight}>
        
        <Burger isOpen={isOpen} toggleMenu={toggleMenu} />
      </div>
    </nav>
  );
};

export default Navbar;
