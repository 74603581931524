import React from 'react';
import MainLayout from '../../components/MainLayout/MainLayout';
import Banner from '../../components/Banner/Banner';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm'
import Statistic from '../../components/Statistic/Statistic';

const HomePage = () => {
  return (
    <MainLayout>
      <Banner />
      <FeedbackForm />
      <Statistic  />
      {/* Остальной контент главной страницы */}
    </MainLayout>
  );
};

export default HomePage;