import React, { useState } from 'react';
import classes from './FeedbackForm.module.scss';
import { FaUpload } from 'react-icons/fa';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    phone: '',
    additionalInfo: '',
    file: null,
    fileName: ''  // Добавляем новое поле для имени файла
  });

  const [statusMessage, setStatusMessage] = useState(''); // Для отображения статуса отправки
  
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      setFormData({
        ...formData,
        [name]: files[0],  // Обновляем файл
        fileName: files[0] ? files[0].name : ''  // Обновляем имя файла
      });
    } else {
      setFormData({
        ...formData,
        [name]: value  // Обновляем другие поля
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Создаем объект FormData для отправки данных
    const data = new FormData();
    data.append('type', formData.type);
    data.append('name', formData.name);
    data.append('phone', formData.phone);
    data.append('additionalInfo', formData.additionalInfo);
    data.append('file', formData.file);  // Отправляем файл

    try {
      // Отправляем запрос на сервер
      const response = await fetch('/api/feedback', {
        method: 'POST',
        body: data,  // Отправляем данные в теле запроса
      });

      const result = await response.json();

      if (response.ok) {
        setStatusMessage('Заявка успешно отправлена');
      } else {
        setStatusMessage('Ошибка при отправке');
      }
    } catch (error) {
      setStatusMessage('Ошибка при отправке');
    }
  };


  return (
    <section className={classes.formComponent}>
      <div className={`${classes.formContainer} ${classes.formBackground}`}>
        <div className={classes.formHeader}>
          <h3 className={classes.formTitle}>
            Участники YUT Profit получают доступ к большему количеству инструментов
          </h3>
        </div>
        <form onSubmit={handleSubmit} className={classes.feedbackForm}>
          <div className={classes.typeSelector}>
            <label className={classes.radioLabel}>
              <input
                type="radio"
                name="type"
                value="ООО"
                checked={formData.type === 'ООО'}
                onChange={handleChange}
                required
              />
              ООО
            </label>
            <label className={classes.radioLabel}>
              <input
                type="radio"
                name="type"
                value="ИП"
                checked={formData.type === 'ИП'}
                onChange={handleChange}
                required
              />
              ИП
            </label>
          </div>
          <div className={classes.inputFields}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Имя*"
              required
              className={classes.inputField}
            />
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Номер телефона*"
              required
              className={classes.inputField}
            />
          </div>
          <div className={classes.inputAdditionalFields}>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              placeholder="Дополнительные примечания (необязательно)"
              className={classes.textArea}
            />
          </div>
          <div className={classes.fileInputWrapper}>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleChange}
              className={classes.fileInput}
            />
            <label htmlFor="file" className={classes.fileLabel}>
              <FaUpload className={classes.icon} />
              <span>{formData.fileName || 'Выбрать файл...'}</span>
            </label>
            <button type="submit" className={classes.submitButton}>Отправить</button>
            {statusMessage && <div className={classes.statusMessage}>{statusMessage}</div>}
          </div>
        </form>
      </div>
    </section>
  );
};

export default FeedbackForm;
