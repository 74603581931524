// src/components/Dropdown/Dropdown.js
import React, { useState } from 'react';
import classes from './Dropdown.module.scss';
import { BiChevronDown } from 'react-icons/bi';

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className={`${classes.menuItemDropdown} ${isOpen ? classes.open : ''}`}>
      <span className={classes.dropdownToggle} onClick={toggleDropdown}>
        {title} <BiChevronDown className={`${classes.icon} ${isOpen ? classes.open : ''}`} />
      </span>
      <div className={classes.dropdownContent}>
        {children}
      </div>
    </li>
  );
};

export default Dropdown;