import React from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import classes from './Burger.module.scss';

const Burger = ({ isOpen, toggleMenu }) => {
  return (
    <div className={classes.burger} onClick={toggleMenu}>
      {isOpen ? <AiOutlineClose size={24} /> : <BiMenuAltRight size={24} />}
    </div>
  );
};

export default Burger;