import React from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './Header.module.scss';

const Header = () => {
  return (
    <header className={classes.header} id="header">
      <Navbar />
    </header>
  );
};

export default Header;