// src/components/Banner/Banner.js
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Banner.module.scss';
import bannerImage from '../../assets/images/image4.jpg';

const Banner = () => {

  
    return (
        
        <section className={classes.heroComponent}>
          <div className={`${classes.heroTop} ${classes.heroTopTextPosLeft} ${classes.heroTopTextBkgImage} ${classes.heroTopDesktopMobileBkg}`}>
            <div className={classes.heroMedia}>
              <img className={classes.heroImage} src={bannerImage} alt="Business banner" />
            </div>
            <div className={`${classes.heroText} ${classes.backgroundColor}`}>
              <h1 className={`${classes.heroTitle} ${classes.titleOnly}`}>
                <span>Бизнес знает, что ему нужно –</span>
                <span className={classes.heroTitleSecondLine}>мы знаем, как это обеспечить.</span>
              </h1>
              <div className={classes.heroDescription}></div>
              <div className={`${classes.heroButton} ${classes.dynamicWidth}`}>
                <Link className={classes.abmBtnOrange} to="/contact-us">
                  Закажи консультацию сейчас
                </Link>
              </div>
            </div>
          </div>
        </section>
        
      );
    };
    
    export default Banner;