import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown';
import DropdownContent from '../Dropdown/DropdownContent';
import classes from './Menu.module.scss';


const Menu = ({ isOpen }) => {
  return (
    <div className={`${classes.navbarBlock} ${isOpen ? classes.open : ''}`} id="menu">
      <ul className={classes.menu}>
        <Dropdown title="Услуги" icon="bx bx-chevron-down">
          <DropdownContent />
          
        </Dropdown>
        <li className={classes.menuItem}><Link to="/about-us" className={classes.menuLink}>О нас</Link></li>
        <li className={classes.menuItem}><Link to="/contact-us" className={classes.menuLink}>Поддержка</Link></li>
      </ul>
    </div>
  );
};

export default Menu;