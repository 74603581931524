import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../components/MainLayout/MainLayout';
import bannerImage from './Profit.png';
import classes from './AboutUs.module.scss';

import certificate1Thumbnail from './2023.jpg';
import certificate1 from './2023.jpg';
import certificate2Thumbnail from './931149.jpg';
import certificate2 from './931149.jpg';
import certificate3Thumbnail from './birzha.jpg';
import certificate3 from './birzha.jpg';

  

const AboutUsPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Что представляет собой программа продаж?",
      answer: "Программа продаж помогает компаниям достичь своих целей, предоставляя инструменты и поддержку для успешной работы."
    },
    {
      question: "Как начать работу с программой продаж?",
      answer: "Чтобы начать работу, зарегистрируйтесь на нашей платформе и следуйте инструкциям для настройки аккаунта."
    },
    // Добавьте больше вопросов и ответов по мере необходимости
  ];
  return (
    <MainLayout>
      <section className={classes.heroComponent}>
          <div className={`${classes.heroTop} ${classes.heroTopTextPosLeft} ${classes.heroTopTextBkgImage} ${classes.heroTopDesktopMobileBkg}`}>
            <div className={classes.heroMedia}>
              <img className={classes.heroImage} src={bannerImage} alt="Business banner" />
            </div>
            <div className={`${classes.heroText} ${classes.backgroundColor}`}>
              <h1 className={`${classes.heroTitle} ${classes.titleOnly}`}>
                <span>Присоединяйтесь к нашей группе ВКонтакте!</span>
                
              </h1>
              <span className={classes.heroTitleSecondLine}>Будьте в курсе последних новостей и обновлений,<br />подписывайтесь на нашу страницу</span>

              <a className={classes.VKButton} href="https://vk.com/yut63" target="_blank" rel="noopener noreferrer">
              ВКонтакте
              </a>
              
            </div>
          </div>
        </section>


        <section className={classes.certificatesSection}>
      <h2 className={classes.certificatesTitle}>Наши сертификаты и документы</h2>
      <p className={classes.certificatesDescription}>Посмотрите наши сертификаты и документы, подтверждающие качество наших услуг.</p>
      <div className={classes.certificatesContainer}>
        <div className={classes.card} onClick={() => handleImageClick(certificate1)}>
          <img src={certificate1Thumbnail} alt="Certificate 1" className={classes.thumbnail} />
        </div>
        <div className={classes.card} onClick={() => handleImageClick(certificate2)}>
          <img src={certificate2Thumbnail} alt="Certificate 2" className={classes.thumbnail} />
        </div>
        <div className={classes.card} onClick={() => handleImageClick(certificate3)}>
          <img src={certificate3Thumbnail} alt="Certificate 2" className={classes.thumbnail} />
        </div>
        {/* Добавьте больше карточек по необходимости */}
      </div>

      {selectedImage && (
        <div className={classes.modal} onClick={handleClose}>
          <span className={classes.close}>&times;</span>
          <img src={selectedImage} alt="Enlarged Certificate" className={classes.modalContent} />
        </div>
      )}
    </section>

    <section className={classes.faqSection}>
      <h2 className={classes.faqTitle}>Часто задаваемые вопросы</h2>
      <div className={classes.faqList}>
        {faqs.map((faq, index) => (
          <div key={index} className={classes.faqItem}>
            <div className={classes.faqQuestion} onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <span className={classes.icon}>
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            <div
              className={`${classes.faqAnswer} ${activeIndex === index ? classes.active : ''}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>


    </MainLayout>
  );
};

export default AboutUsPage;